<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="contents main-section">
      <div v-for="(item, i) in contentArr" :key="i" class="content-item">
        <div class="title">{{ item.title }}</div>
        <div class="content">
          <p v-for="(text, j) in item.content" :key="j" v-html="text"></p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import BreadCrump from "@/components/common/BreadCrump.vue";

export default {
  components: {
    BreadCrump,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "常见问题" }],
      contentArr: [
        {
          title: "【什么是燃料？】",
          content: [
            "燃料是零数开放许可链™服务网络的服务凭证。",
            "成功接入零数开放许可链™后，即可通过标准的文档指引来使用零数科技提供的区块链技术服务。当用户使用区块链网络服务时，每一次在区块链网络生成的链上信息、数据等记录时，都需要消耗一定的区块链资源来完成。",
            "燃料是零数开放许可链™对于区块链资源消耗的数字化服务凭证，使用燃料来对消耗的网络资源进行价值定义，让区块链服务能够在透明、公正、可追溯的规则下，为用户提供可感知可统计的使用体验。",
            "当您持有燃料时，即可享受零数开放许可链™工作台中提供的所有区块链网络服务，不同的技术服务在执行时，会根据网络资源的消耗明确标识出每一次使用将要消耗的燃料数量，让您能够通过统计来规划使用成本和业务需求。",
          ],
        },
        {
          title: "【如何获得燃料？燃料怎么消耗？】",
          content: [
            "燃料可以通过“我的钱包”页面的<span style='font-weight:bold;'>充值</span>或<span style='font-weight:bold;'>燃料兑换券</span>来获得。",
            "在您开通对应的区块链服务后，通过我们的标准技术接入文档来使用区块链网络服务。每一次执行区块链网络服务调用时，系统会从您的可用燃料中扣除相应数量的燃料进行冻结，在该服务成功履行后，将会从您的燃料剩余中成功完成扣除。",
            "每一次燃料数量的变化，包括增加或者扣除，都可以通过“我的钱包”中的燃料明细功能进行查询。",
          ],
        },
        {
          title: "【燃料没有用完，可以退款吗？】",
          content: [
            "暂不支持充值获得的燃料，在未使用的情况下进行退款的操作。",
            "如果您对跟平台的合作有异议，请联系对应的服务专员进行沟通。",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.contents {
  margin-top: 16px;
  .content-item {
    padding: 23px 42px;
    background-color: #fff;
    font-size: 14px;
    color: #3D3D3D;
    margin-bottom: 16px;
    .title {
      font-weight: bold;
      margin-bottom: 30px;
    }
    .content {
      p {
        line-height: 24px;
      }
    }
  }
}
</style>